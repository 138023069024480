
$primary-color:#000000;
$white:#FFFFFF;
$secondary-color:#676767;
$hover-text-color:#00AAFF;
$bg-hover:#EFFAFF;
$bg-hover1:#EBF6FF;



@media screen and (max-width: 680px){
    .bg-color-of-pages{
        background: #fff !important;
    }
}
