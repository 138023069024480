@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');

@import "assets/scss/all-includes";
// @import "../node_modules/bootstrap-icons/font/bootstrap-icons";
// @import url("https://fonths.googleapis.com/css2?family=Poppins:wght@200&display=swap");

@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeflex/primeflex.css";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "~bootstrap/dist/css/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons";

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif, 'primeicons' !important;
  // text-transform: capitalize !important;
}

// input {
//   text-transform: none !important;
// }
a {
  cursor: pointer;
  text-decoration: none !important;
}

body {
  font-family: 'Poppins', sans-serif !important;
  background-color: #eff3f8 !important;
  // background-color: #FBFBFB !important;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -18rem;
  transition: margin 0.25s ease-out;
  width: 245px;
  z-index: 300;
  border-right: 1px solid #E6E6E6 !important;
}

@media (max-width: 768px) {
  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -18rem;
    transition: margin 0.25s ease-out;
    margin-top: 15.5% !important;
    width: 245px;
    z-index: 300;

  }
}

#side-menu li:active {
  background-color: #EAF8FF !important;
  padding: 3% !important;
  border-radius: 4px !important;
}

#page-content-wrapper {
  min-width: 0;
  width: 83% !important;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  border-bottom: 1px solid #DEE2E6;
  margin-top: 1px;

  @media (max-width: 768px) {
    padding: 0 !important;
    font-size: 0 !important;
  }
}

#sidebar-wrapper .list-group {
  width: 16rem;
}

#page-content-wrapper {
  min-width: 100vw;
  margin-left: 245px;
  transition: 0.3s;
  // background-color: #eff3f8 !important;
  // background-color: #FEFEFE !important;
}

@media (max-width: 768px) {
  #page-content-wrapper {
    min-width: 100vw;
    margin-left: 0px;
    transition: 0.3s;
  }
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 84%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.ng-select .ng-select-container .ng-value-container {
  align-items: stretch;
  padding: 0.4375em 0;
  border-top: 0em solid transparent;
}

.ng-select .ng-select-container {
  background-color: #F8F8F8 !important;
  border: none !important;
  height: 44px !important;
  padding: 8px 0;
}

.ng-select .ng-select-container:after {
  border-bottom: none !important;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

#select-branch-signin .ng-select.ng-select-single .ng-select-container {
  height: 24px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #7070709F;
  width: 100%;
  padding: 5px 36px 5px 10px;
}

#select-branch-signin .ng-select .ng-select-container {
  background-color: #FFFFFF !important;
  border: 1px solid #ced4da !important;
  height: 36px !important;
  padding: 8px 0;
  color: red !important;
}

#select-branch-signin .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 2em;
  min-height: 2.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
  text-decoration: none;
  position: relative;
  color: #707070;
  font-size: 14px;
  padding-left: 10px !important;
}

#select-branch-signin {
  .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;
    bottom: 7px !important;
    left: 34px;
  }
}

#select-branch-signin .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #FFFFFF;
  position: absolute;
  top: 9px !important;
  font-weight: 300 !important;
  font-size: 13px;
  margin-left: -6px;
  left: 16px !important;
}

.ng-select.ng-select-single .ng-select-container {
  border: 1px solid #cbd5e1 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  align-self: flex-end;
  bottom: 1px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  align-self: flex-end;
  bottom: 7px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  box-sizing: content-box;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  outline: none;
  padding: 5px 0 !important;
  cursor: default;
  color: #444 !important;
  width: 100%;
}

.preferred-staff-inner .ng-select .ng-select-container {

  width: 100% !important;
}

/***************************/
.ng-select.ng-select-opened .ng-select-container {
  z-index: 1;
}

/*******************************/
::placeholder {
  font-size: 12px;
  color: #aeaeae !important;
  line-height: 18px;
  font-weight: 300 !important;
}




::-webkit-scrollbar {
  width: 8px !important;
  // display: none;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #676767;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eff3f8 !important;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eff3f8 !important;
}

.navbar {
  padding: 0 2%;

  @media (max-width: 768px) {
    padding: 0 3%;
  }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


.swal2-styled.swal2-confirm {
  background-color: #00AAFF;
}


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
/* Prime Icons */



/**********Actions Page*******/
.nav-tabs .nav-item.show .nav-link,
.nav-link.active {
  background-color: #FFFFFF !important;
  color: #00AAFF !important;


}


#page-main-wrapper {
  .nav-tabs {
    display: grid;
    // grid-template-columns: 32% 32% 32%;
    // grid-template-columns: 21% 24% 20%;
    grid-template-columns: 33% 33% 33% !important;
    border: none !important;
  }

  .nav-item {
    width: 100% !important;
    font-size: 12px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}



/********Profile documents*****/



.nav {
  --bs-nav-link-padding-x: 5rem !important;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  z-index: 2000;
}


.nav-tabs .nav-link {

  background-color: #FFFFFF;
  color: #6C6C6C;


}

.p-datatable .p-datatable-thead tr th {
  color: #707070;
  font-size: 12px;
  font-weight: 500;
  background-color: #f8fafc !important;
  padding: 1.5% 1% !important;
}

// /* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

// /* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

// /* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

// /* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

// /* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
// /*******************/


// /* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import '~bootstrap/scss/bootstrap';

.rating-icons {
  margin-right: 10px;

  i {
    color: #DDDDDD;
    margin: 0 6px 0 0;
    cursor: pointer;

    &.disable-rating {
      color: #FFCC36;
    }
  }
}

.assign-job-main-wrapper {
  .ng-select {
    .ng-select-container {
      min-height: 36px !important;
    }

    .ng-value-container {
      padding-bottom: 18px !important;
    }

    .ng-clear-wrapper {
      color: #444 !important;
    }
  }
}

/********for primeng accordian******/
.p-accordion-toggle-icon {
  margin-right: 0.5rem !important;
  position: absolute !important;
  right: 1% !important;

}


.p-accordion-header-link:active {
  box-shadow: none !important;
  outline: none !important;
}


.p-accordion-header-link:focus {
  box-shadow: none !important;
  outline: none !important;
}

.p-button {
  background-color: #00AAFF !important;
  box-shadow: none;
  border: #00AAFF;

  &:active {
    box-shadow: none !important;
    outline: none !important;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

#date-change {
  width: 400px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.p-calendar {
  position: relative;
  display: inline-flex;
  /* max-width: 100%; */
  outline: none !important;

  &:focus {
    border: none !important;
  }

  &:hover {
    border: none !important;
  }

  width: 350px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 10px;

  }

  .p-inputtext {
    box-shadow: none !important;

  }

  &:focus {
    box-shadow: none !important;
  }
}

.p-calendar .p-inputtext {
  border: 1px solid #cbd5e1 !important;
}

.new_service textarea:focus {
  width: 100%;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  // border: 1px solid #00AAFF !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {


  background: #00AAFF !important;
  box-shadow: none;
  outline: none;
  margin-top: 3px;
}

.p-button {
  background-color: #e3e3e3 !important;
  border: 1px solid #e3e3e3;
  color: #949494;
  width: 20px;
  height: 20px;
  box-shadow: none !important;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
  outline: none !important;

  &:hover {
    background-color: #e3e3e3 !important;
    border: none !important;
    color: #949494 !important;
    outline: none !important;
  }

  &:active {

    color: #949494 !important;
    outline: none !important;
  }

  &:focus {
    outline: none !important;
  }
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 17px;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  height: 22px;
  width: 250px;
  font-size: 10px;

}

.p-calendar-w-btn .p-datepicker-trigger {
  background-color: #00AAFF !important;
  height: 47px;

  box-shadow: none !important;
  color: #FFFFFF !important;
  text-align: center;

  &:active {
    box-shadow: none !important;
    outline: none !important;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  // &:hover {
  //   box-shadow: none !important;
  //   outline: none !important;
  // }
}

.p-datepicker table {
  font-size: 11px !important;
  margin: 0.5rem 0 !important;

}

// .p-calendar .p-datepicker {
//   /* min-width: 74% !important; */
//   width: 50%;
// }
.p-datepicker table td {
  padding: 2px;
}

.p-datepicker table th {
  padding: 15px;
}

button:not(:disabled) {
  width: 74px;
  font-size: 10px;
  color: #949494;

}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

.p-button-label {
  font-weight: 500;


}

.p-button {
  background-color: #e3e3e3 !important;
  // border: 1px solid #e3e3e3;
  color: #949494;
  width: 20px;
  height: 20px;
  box-shadow: none !important;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
  outline: none !important;

  &:hover {
    padding: 0.75rem 1.25rem;
  }

  &:focus {
    padding: 0.75rem 1.25rem;
  }

}

#dialog_box_model {
  .p-dialog {
    max-width: none !important;
    width: 65% !important;
  }

  .p-dialog-header {
    width: 100%;

    @media (max-width: 768px) {

      width: 100%;
    }
  }

  .p-dialog-content {
    width: 100%;
    height: 200px;

    @media (max-width: 768px) {

      width: 100%;
    }
  }
}

#picker {
  .p-calendar {
    width: 300px;
    height: 200px;
  }

}

.services_list_wrapper {
  .p-button {
    background-color: #DDF4FF !important;
    color: #00AAFF !important;
    width: 80px;
    height: 30px;
    margin-top: -4px;
    padding: 6px 20px;
    box-shadow: none !important;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 4px;
    outline: none !important;
    font-size: 12px;

    &:hover {
      padding: 0.75rem 1.25rem;

    }

    &:focus {
      padding: 0.75rem 1.25rem;
    }
  }

  .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #343a40;
    height: 22px;
    width: 500px;
    font-size: 10px;


    @media (max-width: 768px) {

      width: 100%;
    }
  }

}

.p-dialog-content {
  width: 500px;
  height: 200px;

  @media (max-width: 768px) {

    width: 100%;
  }
}

.p-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  max-height: 55%;
  max-width: 500px;
  // transform: scale(1);
  position: relative;

  @media (max-width: 768px) {

    width: 100%;
  }
}

.form-group input:focus {
  box-shadow: none !important;
}

.form-group input:active {
  box-shadow: none !important;
}


.ql-toolbar.ql-snow {
  background: #ffffff !important;
  border: 1px solid #cbd5e1 !important;
  border-radius: 6px 6px 0 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {

  &:focus {
    box-shadow: none !important;

    &:active {
      box-shadow: none !important;
    }
  }

}

.ng-select.ng-select-searchable .ng-select-container .ng-value-container .ng-input {
  opacity: 0 !important;
  background: transparent !important;


}


.ng-select-multiple .ng-select-searchable .ng-select-clearable .ng-select .ng-touched .ng-dirty .ng-select-bottom .ng-invalid {
  box-shadow: none !important;
  // border: 2px solid #CBD5E1 !important;
  color: #00AAFF !important;


}



.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {

  box-shadow: none !important;
  border: 2px solid #00AAFF !important;
  color: #00AAFF !important;


  &:focus {
    box-shadow: none !important;
    border: 2px solid #00AAFF !important;
    color: #00AAFF !important;

  }

  &:active {
    box-shadow: none !important;
    border: 2px solid #00AAFF !important;
    color: #00AAFF !important;

  }
}

.ng-select .ng-select-container:hover {
  box-shadow: none !important;
}

.ng-select {
  position: relative;
  display: block;
  box-sizing: border-box;
  // border: 1px solid #CBD5E1 !important;
  border-radius: 4px;
  height: 45px !important;

  // &:active{
  // border: 1px solid #00AAFF !important;

  // }
}

.ng-select .ng-select-container .ng-value-container {
  align-items: stretch;
  padding: 0.4375em 0;
  // background-color: #FFFFFF;


}

.ng-select .ng-select-container {
  background-color: #FFFFFF !important;
  // border: none !important;
  border: 1px solid #ced4da !important;
  height: 42px !important;
  padding: 8px 0;


}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  color: #aeaeae !important;
  position: absolute;
  top: 9px !important;
  font-weight: 400;
  font-size: 12px;
  margin-left: -15px;
  left: 21px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #aeaeae !important;
  position: absolute;
  top: 9px !important;
  font-weight: 400;
  font-size: 14px;
  margin-left: -6px;
  left: 16px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap !important;
  overflow: hidden !important;
  margin-left: 17px !important;
  text-overflow: ellipsis !important;


}

// .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
//   align-self: flex-end;
//   bottom: 3px !important ;

// }
/*****************/
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  height: 45px;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 6px 0px 0px 6px !important;
}

textarea {
  font-size: 12px !important;
}

input[type=date] {
  font-size: 12px;
  // color:#aeaeae !important;
  line-height: 18px;
  font-weight: 300 !important;
}

input[type=date]::placeholder {
  font-size: 12px;
  color: #aeaeae !important;
  line-height: 18px;
  font-weight: 300 !important;
}

input {
  color: #000 !important;
  font-size: 12px !important;

}

/*************profile message alert****/
// .p-message .p-message-wrapper {
//   padding: 1px 8px !important;
// }
// .p-message .p-message-detail {
//   margin-left: 0.5rem;
//   font-size: 12px;
// }
// .p-message .p-message-summary {
//   font-weight: 700;
//   font-size: 12px;
// }

.switch {
  position: relative;
  display: inline-block;
  width: 39px !important;
  height: 20px !important;
}

.bs-chevron-up {
  display: none !important;
}

.bs-chevron-down {
  display: none !important;
}

.modal-body {
  width: 100% !important;
  display: block !important;
}

button:not(:disabled) {
  width: 100px;
  font-size: 12px;
  color: #FFFFFF;
  border-radius: 4px;
}

#page-main-wrapper {
  width: 100%;

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border: none;
  }

  .nav-tabs .nav-link.hover {
    border: none !important;
  }

  .nav-tabs .nav-item.hover {
    border: none !important;
  }

  .nav-tabs .nav-link.focus {
    border: none !important;
  }

  .nav-tabs .nav-item.focus {
    border: none !important;
  }

  .nav-tabs {
    padding: 0.5%;
    // border-radius:4px;
    // border-bottom: none !important;
    // border: 1px solid #EAE8E8 !important;
    border-bottom: 1px solid #E6E6E6 !important;
  }

  .nav-link.active {
    // background-color: #ebf8ff !important;
    border-bottom: 2px solid #00AAFF !important;
    color: #00AAFF !important;
    // border-radius:4px;
    height: 100%;
    animation: bg-slide 20s linear infinite;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .nav-link {
    border: none !important;
    color: #00AAFF !important;
  }
}

#page-branch-main-wrapper {
  width: 100%;

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border: none;
  }

  .nav-tabs .nav-link.hover {
    border: none !important;
  }

  .nav-tabs .nav-item.hover {
    border: none !important;
  }

  .nav-tabs .nav-link.focus {
    border: none !important;
  }

  .nav-tabs .nav-item.focus {
    border: none !important;
  }

  .nav-tabs {
    // padding: 0.5%;
    // border-radius:4px;
    // border-bottom: none !important;
    // border: 1px solid #EAE8E8 !important;
    border-bottom: 1px solid #E6E6E6 !important;
  }

  .nav-link.active {
    // background-color: #ebf8ff !important;
    border-bottom: 2px solid #00AAFF !important;
    color: #00AAFF !important;
    // border-radius:4px;
    height: 100%;
    animation: bg-slide 20s linear infinite;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .nav-link {
    border: none !important;
    color: #00AAFF !important;
  }
}

.nav-link:active {
  color: #00AAFF !important;
}

#page-main-wrapper .nav-item {
  margin-bottom: -8px !important;
}

/*********************/

.bs-timepicker-field {
  width: 65px !important;
  padding: 0.375rem 0.55rem !important;

  @media screen and(max-width:768px) {
    width: 40px !important;
    padding: 0.375rem 0.55rem !important;
    height: 27px !important;

  }
}

.lesson-form-row .btn-default {
  color: #aeaeae !important;
  // background-color:#cbd5e1 !important;
  border: none !important;
  display: inline !important;
  width: 32px !important;

  @media screen and(max-width:768px) {
    // width: 20px !important;
    display: inline !important;
    width: 10px;
    // background-color: red !important;
    margin-left: -19px !important;

  }
}

#side-menu::-webkit-scrollbar {
  width: 8px !important;
  // display: none;
}

/* Track */
#side-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #676767 !important;
  border-radius: 4px !important;
}

/* Handle */
#side-menu::-webkit-scrollbar-thumb {
  background: #A0A0A0 !important;
  border-radius: 4px !important;
}

/* Handle on hover */
#side-menu::-webkit-scrollbar-thumb:hover {
  background: #A0A0A0 !important;
}

.p-datatable table {
  border-collapse: collapse !important;
  min-width: 100% !important;
}

#upload_doc .expandable {
  // overflow: hidden;
  height: 296px;
  // right: 296px;
  // margin-top:2% !important;
  // width: 700px !important;
  border: solid 2px #0af !important;
  border-style: dashed !important;
  // flex-wrap: wrap;
  // position: absolute;
  margin-bottom: 5% !important;
}

.overviw-view {

  .ng-select .ng-select-container {
    cursor: default;
    display: flex;
    outline: none;
    overflow: hidden;
    color: #00AAFF !important;
    border: 2px solid #00AAFF !important;
    position: relative;
    width: 100% !important;
    overflow: hidden;

  }

  .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;

    color: #00AAFF !important;
  }


  .ng-placeholder::before {
    content: url(/assets/icons/upload-doc.svg) !important;
    position: absolute;
    top: -1px !important;
    left: -15px;


  }
}

.upload-module {
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {

    position: absolute;
    color: #FFFFFF !important;
    top: 5px !important;
    top: 11px !important;
    font-weight: 400;
    font-size: 14px;
    margin-left: -6px;
    left: 28px !important;
  }

  .ng-placeholder::before {
    content: url(/assets/icons/upload-cloud-u.svg) !important;
    position: absolute;
    top: -10px !important;
    left: -14px;

  }


  .ng-select .ng-select-container {
    width: 100% !important;
  }

  .ng-select .ng-arrow-wrapper {

    content: url(/assets/icons/chevron-down.svg);
    position: absolute;
    top: 10px;
    right: 0px
  }

  .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: 1px !important;
    color: #444 !important;
  }

  .ng-select .ng-select-container {
    width: 87% !important;
    background-color: #00AAFF !important;
    color: #FFFFFF !important;
  }

}

.upload-module .ng-dropdown-panel.ng-select-bottom {
  top: 100%;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px;
  width: 223px !important;
}

.document-table {
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {

    position: absolute;
    color: #FFFFFF !important;
    top: 5px !important;
    top: 11px !important;
    font-weight: 400;
    font-size: 14px;
    margin-left: -6px;
    left: 28px !important;
  }

  .ng-placeholder::before {
    content: url(/assets/icons/upload-cloud-u.svg) !important;
    position: absolute;
    top: -2px !important;
    left: -14px;

  }


  .ng-select .ng-select-container {
    width: 100% !important;
  }

  .ng-select .ng-arrow-wrapper {

    content: url(/assets/icons/chevron-down.svg);
    position: absolute;
    top: 10px;
    right: 0px
  }

  .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: 1px !important;
    display: none;
  }

  .ng-select .ng-select-container {
    width: 87% !important;
    background-color: #00AAFF !important;
    color: #FFFFFF !important;
  }

}

.document-table .ng-dropdown-panel.ng-select-bottom {
  top: 100%;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px;
  width: 223px !important;
}

.overviw-filter .ng-select .ng-select-container .ng-value-container .ng-placeholder {

  position: absolute;
  color: #FFFFFF !important;
  top: 5px !important;
  top: 11px !important;
  font-weight: 400;
  font-size: 14px;
  margin-left: -6px;
  left: 28px !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  padding: unset;

  .datatable-body-cell {
    padding: 0.75em;
  }
}

.overviw-filter .ng-placeholder::before {
  content: url(/assets/icons/upload-cloud-u.svg) !important;
  position: absolute;
  top: -2px !important;
  left: -14px;

}

.overviw-filter .ng-select .ng-select-container {
  width: 100% !important;
  color: #00AAFF;
}

.overviw-filter .ng-select .ng-arrow-wrapper {

  content: url(/assets/icons/chevron-down.svg);
  position: absolute;
  top: 10px;
  right: 0px
}

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  align-self: flex-end;
  bottom: 1px !important;
  color: transparent !important;
}





.overviw-filter .ng-select .ng-select-container {
  width: 99% !important;
  background-color: #00AAFF !important;
  color: #FFFFFF !important;

}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  color: #FFFFFF !important;
}

.overviw-filter .ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  width: 241px !important;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #FFFFFF;
  position: absolute;
  top: 9px !important;
  font-weight: 400;
  font-size: 14px;
  margin-left: -6px;
  left: 16px !important;
}

.ng-arrow {
  display: block !important;
}



#upload_dialog_box .expandable {


  overflow: hidden;
  height: 296px;
  right: 44px;
  top: 170px;
  flex-wrap: wrap;
  position: absolute;
  width: 437px;
  height: 200px;
  border: 2px solid #00AAFF !important;
  border-style: dashed !important;


}

.p-dialog-header {

  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  height: 22px;
  text-align: center !important;
  width: 521px !important;
  font-size: 10px;


  @media (max-width: 768px) {

    width: 100%;
  }
}


.p-dialog-content {
  width: 521px !important;
  height: 500px !important;

  @media (max-width: 768px) {

    width: 100%;
  }
}

.p-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  max-height: 80% !important;
  max-width: 90% !important;
  // transform: scale(1);
  position: relative;

  @media (max-width: 768px) {

    width: 100%;
  }

  .p-dialog-mask {
    position: fixed;
    top: 94px !important;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

}

.overview-other .ng-placeholder::before {
  content: url(/assets/icons/upload-doc.svg) !important;

  ng-select .ng-select-container {
    cursor: default;
    display: flex;
    outline: none;
    overflow: hidden;
    color: #00AAFF !important;

    position: relative;
    width: 100% !important;
    overflow: hidden;
  }

  .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;

    color: #00AAFF !important;
  }

}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 12px 36px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 2em;
  min-height: 2.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
  text-decoration: none;
  position: relative;
  color: #707070;
  font-size: 14px;
  padding-left: 34px !important;

}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
  font-weight: none !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {

  color: #707070;
  font-size: 14px;
}

//   .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
//     background-color: #f5faff;

//     color: #00AAFF !important;
// }
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: #00AAFF !important;
}

.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  /* word-spacing: 8px; */
  padding-left: 17px;
  padding-top: 13px;
  /* padding: 9px 0; */
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.pricing_tabs {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #00AAFF;
    border-color: #3B82F6;
    color: #FFFFFF;
    height: 7px;
    padding: 17px 31px;
    border-radius: 21px;
    box-shadow: none !important;
    margin: 10px 22px !important;


  }

  .pricing_tabs .p-tabview .p-tabview-nav li .p-tabview-nav-link {

    border: none !important;
    border-width: none !important;
    //     /* background: #ffffff; */
    background: none !important;
    padding: 1.25rem;
    font-weight: 300;
    //  transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }

  .p-tabview .p-tabview-nav {
    background: transparent !important;
    border: none !important;
    border-width: none !important;
  }

  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {

    // border-color: #adb5bd;
    border: none !important;
    color: #6c757d;
    background: none !important;
  }

  // .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  //   background:none; 
  //   border-color: #adb5bd;
  //   color: black;
  // }
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none !important;
  }

  .p-tabview-nav-container {
    box-shadow: 0px 4px 30px rgb(221 224 255 / 54%);
    border-radius: 43px;
    width: 19%;
    position: absolute;
    right: 33%;

    @media screen and (max-width:768px) {


      width: 300px !important;
      position: absolute;
      right: 20% !important;

    }
  }

  .p-tabview .p-tabview-panels {
    background: transparent !important;
    /* padding: 1.25rem; */
    border: 0 none;
    color: #000;
    // margin-top: 2%;
    /* top: 107%; */
    // position: absolute;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: none !important;
    border-width: none !important;
    // border-color: transparent transparent #dee2e6 transparent;
    background: #ffffff;
    // color: #6c757d; 
    padding: 1.25rem;
    font-weight: 300;
    // border-top-right-radius: 6px;
    // border-top-left-radius: 6px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }

  .p-tabview-nav-content {
    overflow-x: none;
    overflow-y: none;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overscroll-behavior: none;
  }

  .p-tabview-title {
    line-height: 1;
    font-size: 22px;
    white-space: nowrap;
  }
}

#select-service-category {
  .ng-select .ng-clear-wrapper {
    color: #999;
    bottom: 2px;
  }
}

.mobile-plan-include {
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    color: #000;
    background-color: #f7fcff;
    font-weight: 500;
    border-radius: 6px;
    transition: box-shadow 0.2s;
  }

  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background-color: #f7fcff;
    color: #343a40;
  }

  .p-accordion-header-link:focus {
    z-index: 1;

    background-color: #f7fcff;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: #f7fcff;
    color: #343a40;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-accordion .p-accordion-content {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    font-weight: 300;
    font-size: 14px;
    color: #788291;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-accordion-toggle-icon {
    margin-right: 0.5rem !important;
    position: absolute !important;
    right: 1% !important;
    display: none;
  }
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  background: #6c757d3d !important;
}

.p-datepicker-title,
.p-datepicker-year,
.p-datepicker-title,
.p-datepicker-month,
p-calendar .p-link {
  color: #495057 !important;
}

.cp-wrapper .p-inputtext {
  margin: 0;
  width: 20rem !important;
}


.myjobs-wrapper .ng-select {
  position: relative;
  display: block;
  width: 180px;
  box-sizing: border-box;
}

.myjobs-wrapper .tbl-hold {
  overflow: visible !important;
}

.add-man-modal {
  .p-dialog {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) !important;
    width: 100% !important;
    align-items: center !important;
    max-width: 0% !important;
  }

  .pi {
    color: #444;
  }

  .p-dialog-mask {
    opacity: 1 !important;
  }
}

.overviw-filter .ng-select .ng-select-container {
  color: #FFFFFF !important;
}


.add-man-modal .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  color: #444 !important;
}


.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 200000 !important;
}

.d-block.modal.fade.show {
  margin-top: 4% !important;
}

.modal.show .modal-dialog {
  transform: none;
  margin-top: 0% !important;
}


// .settings-wrapper {
//   .nav-tabs {
//     border: none !important;
//   }
// }

.settings-wrapper {
  width: 100% !important;

  .nav-tabs {
    border: none !important;
    width: 60.6%;
    display: grid;
    // background: azure;
    grid-template-columns: 33% 33% 33%;

    .nav-item {
      text-align: center !important;
    }
  }
}

.profile-wrapper {
  .nav-tabs {
    border: none !important;
    width: 100% !important;
    display: grid;
    grid-template-columns: 22% 22% 22%;

    .nav-item {
      text-align: center !important;
    }

    .nav-tabs .nav-link {
      font-weight: 700 !important;
    }
  }
}


.branch-wrapper {
  .nav-tabs {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
  }
}


.form-data-profile {
  .nav-tabs {
    border: none !important;

    .nav-item {
      margin: 0 !important;
      padding: 0 !important;
    }

    .nav-link .active {
      border: none !important;
    }
  }
}

// #page-main-wrapper .nav-tabs {
//   .nav-tabs {
//     display: grid;
//     grid-template-columns: 50% 50% !important;
//   }
// }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  // border: 1px solid #ffffff !important;
}

.branch-wrapper .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  color: #444 !important;
}

.create-new-service .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  color: #444 !important;
}

.location-modal .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  color: #444 !important;
}

.service-request .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  color: #444 !important;
}

.d-block.modal.fade.show {
  margin-top: 4% !important;
  backdrop-filter: contrast(0.5) !important;
  z-index: 30000;
}


.preferred-staff-section .sb-dropdonws .dropdown-input .bi-chevron-down {
  margin-left: 0;
  font-size: 12px;
  position: absolute !important;
  right: 20px !important;
  top: 65px !important;
}

.planner-address-screen .card-input-element+.card {
  padding: 5px 9px !important;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 12%) !important;
  border: 2px solid transparent !important;
  border-radius: 4px !important;
  z-index: 1 !important;
}

#locationModal-home #main-btn-close {
  background: none !important;
  color: #444 !important;
  box-shadow: none !important;
}

.add-holiday-wrapper .p-calendar .p-inputtext {
  height: 40px !important;
  width: 100% !important;

  .form-group {
    width: 100% !important;
    height: 40px !important;
  }
}

.add-holiday-wrapper .form-group input {
  height: 40px !important;
}

.add-holiday-wrapper .p-calendar-w-btn .p-datepicker-trigger {
  height: 40px !important;
}


.profile-wrapper {
  .nav-tabs {
    .nav-tabs .nav-link {
      font-weight: 700 !important;
    }
  }
}

#tab-set-wrapper .nav-tabs .nav-link {
  font-weight: 500 !important;
  font-size: 15px;
  color: #314F69 !important;
  padding: 6% 0 !important;
}

.overlay_loader {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  // rgba(74, 74, 74, .8);
  z-index: 99999;
  // background-color:#eceaea;
  // opacity: 0.4;
  // filter: alpha(opacity=40);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.planner-main-wrapper {
  .form-check-input[type=radio] {
    margin-top: 3px !important;
  }
}

.availability-main-wrapper {
  .sb-dropdonws .dropdown-input {
    color: #000000 !important;
    font-size: 12px;
  }
}

.man-req {
  p {
    font-size: 13px;
  }
}


.dateselection-wrapper {
  .p-carousel .p-carousel-indicators {
    display: none !important;
  }

  .p-calendar {
    width: 40%;
    // border: 1px solid #00aaff !important;
    // border-radius: 4px !important;
    // background: #ffffff;
    background: rgba(255, 255, 255);
    border: 1px solid #EAF8FFCC !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
  }

  .p-calendar .p-inputtext {
    border: none !important;
  }

  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    background: #00AAFF !important;
    color: #FFFFFF;
  }

  ::placeholder {
    color: #000000 !important;
    font-weight: 400;
    font-size: 14px;
  }
}


#page-main-wrapper {
  .tab-content {
    padding: 2% !important;
  }
}


.modal.show .modal-dialog {
  transform: none;
  margin-top: 3% !important;
}

.profile-wrapper {
  padding: 0 0 4% 0 !important;

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #00AAFF !important;
  }
}

.profile-wrapper {
  .nav-tabs {
    // padding: 0.5% !important;
    // border-bottom: 1px solid #E6E6E6 !important;
    display: grid !important;
    grid-template-columns: 33% 33% 33% !important;
  }

  .nav-link.focus {
    border: none !important;
  }

  .nav-link.active {
    // background-color: #ebf8ff !important;
    border-bottom: 2px solid #00AAFF !important;
    color: #00AAFF !important;
    // border-radius:4px;
    height: 100%;
    animation: bg-slide 20s linear infinite;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .nav-link {
    border: none !important;
    color: #00AAFF !important;
  }
}

.profile-wrapper .nav-item {
  margin-bottom: -8px !important;
}


#settings-main-page-wrapper {
  padding: 0 !important;

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #00AAFF !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .nav-tabs .nav-link.focus,
  .nav-tabs .nav-item.show .nav-link {
    border: none !important;
  }
}

#settings-main-page-wrapper {
  margin-bottom: -8px !important;
}

#settings-main-page-wrapper {
  .nav-tabs {
    padding: 0 1% !important;
    border-bottom: 1px solid #E6E6E6 !important;
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20%!important;
  }

  .nav-link.active {
    // background-color: #ebf8ff !important;
    border-bottom: 2px solid #00AAFF !important;
    color: #00AAFF !important;
    // border-radius:4px;
    height: 100%;
    animation: bg-slide 20s linear infinite;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .nav-link {
    border: none !important;
    color: #00AAFF !important;
  }
}

#a-branch-main-page-wrapper {
  padding: 0 !important;

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #00AAFF !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .nav-tabs .nav-link.focus,
  .nav-tabs .nav-item.show .nav-link {
    border: none !important;
  }
}

#a-branch-main-page-wrapper .nav-item {
  margin-bottom: -8px !important;
}

#a-branch-main-page-wrapper {
  .nav-tabs {
    padding: 0.5% !important;
    border-bottom: 1px solid #E6E6E6 !important;
    display: grid !important;
    grid-template-columns: 25% 25% 25% !important;
  }

  .nav-link.active {
    // background-color: #ebf8ff !important;
    border-bottom: 2px solid #00AAFF !important;
    color: #00AAFF !important;
    // border-radius:4px;
    height: 100%;
    animation: bg-slide 20s linear infinite;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .nav-link {
    border: none !important;
    color: #00AAFF !important;
  }
}

#manage-branches-main-wrapper {

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #00AAFF !important;
  }
}

#manage-branches-main-wrapper {
  .nav-tabs {
    padding: 0.5% !important;
    border-bottom: 1px solid #E6E6E6 !important;
    display: grid !important;
    grid-template-columns: 10% 10% 10% !important;
  }

  .nav-link.active {
    // background-color: #ebf8ff !important;
    border-bottom: 2px solid #00AAFF !important;
    color: #00AAFF !important;
    // border-radius:4px;
    height: 100%;
    animation: bg-slide 20s linear infinite;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .nav-link {
    border: none !important;
    color: #00AAFF !important;
  }
}

#manage-branches-main-wrapper {
  .nav-tabs .nav-link {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #314F69 !important;
    padding-bottom: 2% !important;
  }
}

#manage-branches-main-wrapper .nav-item {
  padding-bottom: -8px !important;
}

#page-main-wrapper .nav-item {
  margin-bottom: -8px !important;
}

.page-mainn-wrapper .nav-tabs {
  display: grid !important;
  grid-template-columns: 10% 10% 10% !important;
  border: none !important;
  grid-column-gap: 2%;
}

.page-mainn-wrapper .nav-tabs .nav-link {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #314F69 !important;
  padding: 0 0 10% 14% !important;
}

#tab-set-wrapper .nav-tabs .nav-link:focus-visible {
  border: none !important;
}

.s-booking-history .ng-select .ng-select-container {
  background-color: #FFFFFF !important;
  border: 1px solid #ced4da !important;
  height: 47px !important;
  padding: 8px 0;
}

.s-req-history {
  .p-sidebar-right {
    top: 0;
    right: 0;
    width: 30rem;
    height: 100%;
    // background: #f8fafc !important;
  }
}

.add-recurring-section {
  .p-sidebar-right {
    top: 0;
    right: 0;
    width: 35rem;
    height: 100%;
    // background: #f8fafc !important;
  }

  .p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-top: 0;
    height: inherit;
  }

  .p-inputnumber-input {
    height: 42px;
  }

  p-inputnumber,
  .p-inputnumber {
    display: grid;
  }
}

body {
  overflow-x: hidden !important;
}



.add-address-section {
  .p-sidebar .p-sidebar-header {
    display: none !important;
  }

  .p-sidebar-right {
    bottom: 0;
    right: 0;
    padding: 0 2%;
    width: 35rem;

    // height: 30rem;
    // background: #f8fafc !important;
    @media (max-width: 768px) {
      // height: 30rem;
    }
  }

  .p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-top: 0;
    height: inherit;
  }

  .p-sidebar-content {
    overflow: auto !important;

    /* width */
    &::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #E6E6E6;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}



.page-titles {
  background: #ffffff;
  border: 1px solid #dfe7ef;
  padding: 1.3% 0 0 2% !important;
  margin-bottom: 2rem !important;
  box-shadow: 0px 4px 30px rgba(221, 224, 255, .54);
  border-radius: 10px;
}

.card-for-add-branch {
  background: #ffffff;
  border: 1px solid #dfe7ef;
  // padding: 2% 2.5% !important;
  margin-bottom: 2rem !important;
  box-shadow: 0px 4px 30px rgba(221, 224, 255, .54);
  border-radius: 10px;
}

.profile-card {
  background: #ffffff;
  border: 1px solid #dfe7ef;
  // padding: 2% !important;
  margin-bottom: 2rem !important;
  box-shadow: 0px 4px 30px rgba(221, 224, 255, .54);
  border-radius: 10px;
}

.card {
  background: #ffffff;
  border: 1px solid #dfe7ef;
  padding: 2% 2.5% !important;
  margin-bottom: 2rem !important;
  box-shadow: 0px 4px 30px rgba(221, 224, 255, .54);
  border-radius: 10px;
}

.control-label {
  color: #444 !important;
  font-weight: 300 !important;
  margin-bottom: 0.6rem;
  font-size: 14px !important;

  @media (max-width: 768px) {
    margin-top: 4.5%;
  }
}

.required-symbol {
  color: #FF0000;
}

.sr-tabs-wrapper {
  ul.nav.nav-tabs {
    display: grid;
    grid-template-columns: 33% 33% 33% !important;
    border-bottom: 1px solid #F6F6F6 !important;
  }

  li.active.nav-item.ng-star-inserted {
    border-bottom: 2px solid #00AAFF !important;
  }
}


// .sr-tabs-wrapper {
//   .dropdown-menu .show .ng-star-inserted{
//     transform: translate3d(100px, 228px, 0px) !important;
//   }
// }


.form-data-profile, .profile-card {
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #bbbbbb !important;
    background: #F6F6F6 !important;
    border: none;
  }
}



.booking-details  {
  .p-sidebar-right {
    top: 0;
    right: 0;
    width: 30rem !important;
    height: 100%;
    // background: #f8fafc !important;
  }
}

.booking-details  .p-sidebar-close-icon {
  display: none !important;
}