@import "variables";

.sb-primary-btn{
    background: #00AAFF;
    border: 0;
    outline: 0;
    height: 34px;
    border-radius: 3px;
    padding: 5px 15px;
    color: $white;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover{
        background: #03A1F0;
    }
    &:disabled{
        cursor: not-allowed;
    }
}

.sg-input-text{
    width: 100%;
    position: relative;
    .input-group{
        flex-wrap: nowrap;
        input{
            border-radius: 0px 4px 4px 0px;
        }
        .input-group-text{
            font-size: 12px;
            border: 1px solid #7070709F;
            padding: 5px 10px;
        }
    }
    sup{
        color: #E04400;
    }
    .label-text{
        color: #000000;
        font-size: 12px;
        margin-bottom: 4px;
        font-weight:500;
        @media screen and (max-width: 600px){
            font-weight: 600;
        }
    }
    input{
        background: #ffffff !important;
        border: 1px solid #cbd5e1 !important;
        border-radius:4px;
        padding: 10px 10px;
        box-shadow: none !important;
       
        height: 34px !important;
        padding: 10px;
        font-size: 14px;
        color: #000000;
        width: 100%;
        line-height: normal;
        margin: 0 !important;
        outline: 0 !important;
        &::placeholder{
            font-size: 12px;
            color:#aeaeae !important;
            line-height: 18px;
            font-weight: 300 !important;   
        }
        &:focus{
            border: 1px solid #cbd5e1 !important;
        }
        &:active{
            border: 1px solid #cbd5e1 !important;
        }
        &:disabled{
            border: 1px solid #cbd5e1 !important;
            color: #707070;
        }
        &.error{
            border: solid 1px red !important;
            &::placeholder{
                color: #000000;   
                opacity: 1;      
            }
        }
    }
    .error-msg{
        font-size: 12px;
        margin-top: 5px;
        color: #E04400;
        line-height: 1.17;
    }
}
